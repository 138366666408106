import { Design, Price } from 'mycs/api/DesignAPI';

export enum FurnitureType {
  Pyllow = 'pyllow',
  Flayr = 'flayr',
  Shelf = 'shelf',
  Cushion = 'cushion',
}

export enum Filter {
  AllProducts = 'all-products',
  ShelvesAndCabinets = 'shelves-and-cabinets',
  AllSofas = 'all-sofas',
  FlayrSofas = 'flayr-sofas',
  Cushion = 'cushion',
  Pyllow = 'pyllow',
}

export enum DiscountRate {
  Discount50 = '-50%',
  Discount60 = '-60%',
}

export enum DiscountBoxType {
  Filter = 'filter',
  ProductPreview = 'productPreview',
}

export const filterToFurnitureTypes: Record<Filter, FurnitureType[]> = {
  [Filter.ShelvesAndCabinets]: [FurnitureType.Shelf],
  [Filter.FlayrSofas]: [FurnitureType.Flayr],
  [Filter.Cushion]: [FurnitureType.Cushion],
  [Filter.Pyllow]: [FurnitureType.Pyllow],
  [Filter.AllSofas]: [FurnitureType.Pyllow, FurnitureType.Flayr],
  [Filter.AllProducts]: [
    FurnitureType.Shelf,
    FurnitureType.Pyllow,
    FurnitureType.Cushion,
    FurnitureType.Flayr,
  ],
};

export type TypeOrFilter = FurnitureType | Filter;

export interface Option {
  furnitureType: Filter;
  furnitureTypes: FurnitureType[];
  label: string;
  localizedLabel: string;
  quantity: number;
}

export interface FurnitureDesign extends Omit<Design, 'price'> {
  price: Price;
}
