import { Fragment, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import type { CfGridBanner } from '@mycs/contentful';

import type { Design } from 'mycs/shared/services/DesignApiService/DesignApiService';
import { MaterialEOLWarning } from '../MaterialEOLWarning/MaterialEOLWarning';
import { useDevice } from 'mycs/router/DeviceContext';
import { useFurnitureTypeFilter } from 'mycs/hooks/useFurnitureTypeFilter';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useUrlPagination } from 'mycs/hooks/useUrlPagination';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import GridBanner from 'mycs/shared/components/GridBanner/GridBanner';
import GridSplitBanner from 'mycs/shared/components/GridSplitBanner/GridSplitBanner';
import Pagination from 'mycs/shared/components/Pagination/Pagination';
import ProductPreview from 'mycs/shared/components/ProductPreview/ProductPreview';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import UIUtils from 'mycs/shared/utilities/UIUtils/UIUtils';

import styles from './ProductsGrid.scss';
import { DiscountBoxType } from 'mycs/types/furnitureTypeFilter';
import DiscountBox from '../DiscountBox/DiscountBox';
import { usePromoPage } from 'mycs/hooks/usePromoPage';

const defaultGridItemsPerPage = {
  shortGrid: {
    smallScreen: 6,
    bigScreen: 12,
  },
  longGrid: {
    smallScreen: 10,
    bigScreen: 24,
  },
};

const maxBannersForShortGrid = 3;

const SplitGridType = 'Split CTA Banner';

type Props = {
  className?: string;
  designs: Design[];
  getFurnitureLabel?: (label: string | null) => string | undefined;
  gridBanners?: CfGridBanner[];
  bannerDesigns?: Design[];
  hideFurnitureTypeFilter?: boolean;
  id?: string;
  onRemoveCallback?: (uuid?: string) => void;
  onUndoRemoveCallback?: (uuid?: string) => void;
  perPage?: number;
  previewProps?: { [prop: string]: any };
  removedDesigns?: string[];
  showProductMeasurements?: boolean;
  showRemoveButton?: boolean;
  title?: string;
  titleClassName?: string;
  noPagination?: boolean;
};

export default function ProductsGrid({
  className,
  designs,
  getFurnitureLabel,
  gridBanners = [],
  bannerDesigns = [],
  hideFurnitureTypeFilter,
  id,
  onRemoveCallback,
  onUndoRemoveCallback,
  perPage,
  previewProps = { hasAddToCart: true },
  removedDesigns = [],
  showProductMeasurements = false,
  showRemoveButton = false,
  title,
  titleClassName,
  noPagination,
}: Props) {
  const { activePage } = useUrlPagination();
  const containerRef = useRef<HTMLDivElement>(null);
  const { locale, countryCode } = useLocale();
  const device = useDevice();
  const { pathname, search } = useLocation();
  const isSmallScreen = device.hasPhoneDimensions;
  const isTablet = device.isTablet || isSmallScreen;
  const currentPageUrl = `${pathname}${search}`;
  const { isPromoPage } = usePromoPage();

  const isShortGrid = gridBanners.length <= maxBannersForShortGrid;
  const pageSize = noPagination
    ? Number.MAX_SAFE_INTEGER
    : getPageSize(perPage, isSmallScreen, isShortGrid);

  const { FurnitureTypeFilter, filteredDesigns: products } =
    useFurnitureTypeFilter({
      designs,
      getFurnitureLabel,
    });

  useEffect(() => {
    //FIXME: everytime the products list change it should be tracked
    track(products as Design[]);
  }, []);

  return (
    <div ref={containerRef} id={id} className={className}>
      {title && <SafeText content={title} className={titleClassName} />}

      {!hideFurnitureTypeFilter && (
        <div className={styles.furnitureTypefilters} data-testid="Filter">
          <FurnitureTypeFilter />
        </div>
      )}

      <MaterialEOLWarning designs={products} />

      <div className={styles.container}>
        {isSmallScreen ? (
          renderMobile()
        ) : (
          <>
            {renderPage(activePage - 1)}
            {renderPagination(products)}
          </>
        )}
      </div>
    </div>
  );

  function track(designs: Design[]) {
    const contentsLoaded = designs.some((p) => p.price);
    if (contentsLoaded) {
      AnalyticsService.trackProductList(
        designs,
        locale,
        countryCode,
        currentPageUrl
      );
    }
  }

  /**
   * Render the mobile version: an "endless" grid + a Load More button
   */
  function renderMobile() {
    const totalPages = getTotalPages(products);

    // All pages up to the current one at once
    const pages = [];
    for (let i = 1; i < activePage; i++) {
      pages.push(<div key={i}>{renderPage(i - 1)}</div>);
    }

    pages.push(
      <div key={activePage} ref={containerRef}>
        {renderPage(activePage - 1)}
      </div>
    );

    return (
      <div>
        {pages}
        <Pagination
          totalPages={totalPages}
          onPageChange={onPageChange}
          isLoadMore
        />
      </div>
    );
  }

  /**
   * Render the product grid
   */
  function renderPage(page: number): React.ReactNode {
    const numGridBannersPerPage = isShortGrid || isSmallScreen ? 2 : 3;
    const gridBannersPerPage = gridBanners.slice(
      page * numGridBannersPerPage,
      page * numGridBannersPerPage + numGridBannersPerPage
    );
    const designsPerPage = isSmallScreen
      ? pageSize
      : pageSize - numGridBannersPerPage * gridBannersPerPage.length;

    const list = products.slice(
      page * designsPerPage,
      (page + 1) * designsPerPage
    );

    if (!list.length && page > 0) {
      const newPage = page - 1;
      return renderPage(newPage);
    }

    return renderDesigns(list as Design[], gridBannersPerPage);
  }

  function renderDesigns(
    designs: Design[],
    gridBanners: CfGridBanner[]
  ): React.ReactNode {
    const previews: React.ReactNode[] = designs.map((product) => {
      // Sizes for SmartImage (mobile is reduced on purpose in order to get 500*500 resolution)
      const imageSizes = '(max-width: 767px) 10vw, 33vw';
      const isRemoved = removedDesigns.indexOf(product.uuid) > -1;
      // const isFlayr = product.furniture_type === FurnitureType.Flayr;

      return (
        <div
          className={styles.preview}
          key={product.uuid}
          data-testid="product-preview"
        >
          {isPromoPage() && (
            <DiscountBox boxType={DiscountBoxType.ProductPreview} />
          )}
          <ProductPreview
            {...previewProps}
            product={product}
            imageSizes={imageSizes}
            hasRemoveButton={showRemoveButton}
            onRemoveCallback={onRemoveCallback}
            onUndoRemoveCallback={onUndoRemoveCallback}
            isRemoved={isRemoved}
            showProductMeasurements={showProductMeasurements}
          />
        </div>
      );
    });

    if (gridBanners && gridBanners.length) {
      let gridPosition = 0;
      let gridsInserted = 0;
      // 1 grid for every 4 designs
      const maxGrids = Math.floor(designs.length / 4);
      for (let i = 0; i < gridBanners.length; i++) {
        let bannerPosition = 0;
        if (isShortGrid) {
          bannerPosition = getShortGridBannerPosition(i);
        } else {
          const gap = 4;
          const mobileGap = i === 0 ? 4 : 5;
          if (isSmallScreen || isTablet) {
            gridPosition += mobileGap;
          } else {
            // Last banner position for a full page
            const lastBannerPosition = 12;
            gridPosition += gap;
            if (gridPosition === lastBannerPosition) gridPosition += gap / 2;
          }
          bannerPosition = gridPosition;
        }
        if (gridsInserted < maxGrids) {
          previews.splice(
            bannerPosition,
            0,
            renderGridBanner(gridBanners[i], i)
          );
          gridsInserted++;
        } else {
          break;
        }
      }
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.products}>{previews}</div>
      </div>
    );
  }

  function renderGridBanner(
    data: CfGridBanner,
    index: number
  ): React.ReactNode {
    return (
      <Fragment key={data._id}>
        {data.type === SplitGridType ? (
          <GridSplitBanner
            imageData={data.customImage}
            title={data.customTitle}
            cta={data.ctaLink}
            reverseImage={index % 2 !== 0}
          />
        ) : (
          <div className={classNames(styles.preview, styles.featuredProduct)}>
            <GridBanner
              data={data}
              imageSizes="(max-width: 767px) 100vw, 50vw"
              previewProps={previewProps}
              bannerDesigns={bannerDesigns}
            />
          </div>
        )}
      </Fragment>
    );
  }

  function renderPagination(designs: any[]): React.ReactNode {
    const totalPages = getTotalPages(designs);

    if (totalPages <= 1) return null;

    return (
      <div className={styles.pagination} data-testid="pagination">
        <Pagination totalPages={totalPages} onPageChange={onPageChange} />
      </div>
    );
  }

  function onPageChange() {
    //no need to scroll on mobile
    if (isSmallScreen) {
      return;
    }

    window.scrollTo(0, 0);
    UIUtils.scrollToElement(containerRef.current as Element, {
      duration: 600,
      offset: -50,
    });
  }

  function getTotalPages(designs: any[]): number {
    return Math.ceil(designs.length / pageSize);
  }

  // Desktop: 4th and 8th position, mobile: 4th and 9th position
  function getShortGridBannerPosition(i: number) {
    return isSmallScreen || isTablet ? 5 * i + 4 : 4 * i + 4;
  }
}

function getPageSize(
  perPage: number | undefined,
  isSmallScreen: boolean,
  isShortGrid: boolean
) {
  const perPageShortGridLength = isSmallScreen ? 8 : perPage;
  const { longGrid, shortGrid } = defaultGridItemsPerPage;

  const shortGridLength =
    perPageShortGridLength ||
    (isSmallScreen ? shortGrid.smallScreen : shortGrid.bigScreen);

  if (isShortGrid || isSmallScreen) return shortGridLength;

  // Ignore per page if it is long grid ( gridBanners.length > maxBannersForShortGrid)
  // https://mycshq.atlassian.net/browse/MYCS-11317
  return longGrid.bigScreen;
}
