import { useLocale } from 'mycs/shared/state/LocaleContext';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

export const useTranslate = () => {
  const { locale } = useLocale();

  const translate = (key: string) => I18nUtils.localize(locale, key);

  return translate;
};
