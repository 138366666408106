import { PureComponent } from 'react';
import classNames from 'classnames';

import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import CollapsingPanel from 'mycs/shared/components/CollapsingPanel/CollapsingPanel';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

import styles from './CountFilter.scss';
import { DiscountBoxType, Filter } from 'mycs/types/furnitureTypeFilter';
import DiscountBox from '../DiscountBox/DiscountBox';
import { useLocation } from 'react-router-dom';
import { usePromoPage } from 'mycs/hooks/usePromoPage';

type OptionType = {
  label: string;
  localizedLabel: string;
  quantity: number;
};

type Props = {
  options: OptionType[];
  selectedLabel?: string | null;
  onSelect: (label: any) => void;
  allValue?: string;
  filterLabel?: string;
  optionsOnly?: boolean;
};

export default function CountFilter(props: Props) {
  const { locale } = useLocale();
  const { pathname } = useLocation();
  const device = useDevice();
  const { isPromoPage } = usePromoPage();

  return (
    <InnerCountFilter
      {...props}
      locale={locale}
      isSmallScreen={device.hasPhoneDimensions}
      pathname={pathname}
      isPromoPage={isPromoPage}
    />
  );
}

type InnerProps = Omit<Props, 'allValue'> & {
  locale: string;
  isSmallScreen: boolean;
  allValue: string;
  pathname: string;
  isPromoPage: () => boolean;
};

export class InnerCountFilter extends PureComponent<InnerProps> {
  static defaultProps: Partial<InnerProps> = {
    allValue: `filters/subcat/${Filter.AllProducts}`,
    filterLabel: 'Filters',
    optionsOnly: false,
  };

  /**
   * Select a filter
   */
  onClickHandler(option: any) {
    if (
      !this.props.selectedLabel ||
      option.label !== this.props.selectedLabel
    ) {
      this.props.onSelect(
        option.label === this.props.allValue ? null : option.label
      );
    }
  }

  /**
   * Render a filter link
   */
  renderLink(
    option: OptionType,
    index: number,
    selectedLabel?: string
  ): React.ReactNode {
    const filterClasses = classNames(styles.filter, {
      [styles.filterActive]: selectedLabel === option.label,
    });
    const showDiscountBox =
      option.label !== `filters/subcat/${Filter.AllProducts}`;
    const isFlayr = option.label === Filter.FlayrSofas;

    const onClick = () => this.onClickHandler(option);

    return (
      !isFlayr && (
        <Alink
          key={`${option.localizedLabel}_${index}`}
          className={filterClasses}
          onClick={onClick}
          text={option.label}
        >
          <span className={styles.text}>{option.localizedLabel}</span>
          {showDiscountBox && <DiscountBox boxType={DiscountBoxType.Filter} />}
        </Alink>
      )
    );
  }

  render() {
    const { options, allValue, filterLabel, optionsOnly } = this.props;
    const selectedLabel = this.props.selectedLabel || allValue;

    const allOption = {
      label: allValue,
      localizedLabel: I18nUtils.localize(this.props.locale, allValue),
      quantity: options.reduce((sum, item) => sum + item.quantity, 0),
    };

    const items = optionsOnly ? options : [allOption].concat(options);
    const links = items.map((option, idx) =>
      this.renderLink(option, idx, selectedLabel)
    );
    const isBlackText = this.props.isPromoPage();

    return (
      <div className={styles.filters}>
        {this.props.isSmallScreen ? (
          <CollapsingPanel
            iconName="general/arrow-chevron-down.svg"
            isCompactLayout
            isTransparent
            iconClassName={styles.chevron}
            className={styles.panel}
            contentClassName={styles.panelContent}
            headClassName={styles.panelHead}
            headlineClassName={styles.panelHeadline}
            headline={filterLabel}
            isBlackText={isBlackText}
            content={<div>{links}</div>}
          />
        ) : (
          links
        )}
      </div>
    );
  }
}
