import classNames from 'classnames';
import styles from './DiscountBox.scss';
import { DiscountBoxType, DiscountRate } from 'mycs/types/furnitureTypeFilter';

export interface DiscountBoxProps {
  boxType: DiscountBoxType;
  isFlayr?: boolean;
}

export default function DiscountBox({
  boxType,
  isFlayr = false,
}: DiscountBoxProps) {
  const discountBoxClasses = classNames(styles.discountBox, {
    [styles.specialFilter]: boxType === DiscountBoxType.Filter && isFlayr,
    [styles.productPreview]: boxType === DiscountBoxType.ProductPreview,
  });
  const discount = isFlayr ? DiscountRate.Discount60 : DiscountRate.Discount50;

  return <span className={discountBoxClasses}>{discount}</span>;
}
