import { useLocation } from 'react-router-dom';
import { useTranslate } from './useTranslate';

export function usePromoPage() {
  const { pathname } = useLocation();
  const translate = useTranslate();

  const isPromoPage = () => {
    const currentPromotionPage = translate(
      'current-promotion-page'
    ).toLowerCase();
    return pathname.includes(currentPromotionPage);
  };

  return { isPromoPage };
}
