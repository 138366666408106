import countBy from 'lodash/countBy';
import uniqBy from 'lodash/uniqBy';
import sum from 'lodash/sum';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useUrlFilter } from 'mycs/hooks/useUrlFilter';
import CountFilter from 'mycs/shared/components/CountFilter/CountFilter';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import {
  Filter,
  FurnitureDesign,
  FurnitureType,
  Option,
  filterToFurnitureTypes,
} from 'mycs/types/furnitureTypeFilter';

export function useFurnitureTypeFilter({
  designs = [],
  getFurnitureLabel,
}: {
  designs: FurnitureDesign[];
  getFurnitureLabel?: (label: string | null) => string | undefined;
}) {
  const { locale } = useLocale();
  const { selectedFilter: selectedFilterString, updateFilter } =
    useUrlFilter('Filter');
  const validFilters = new Set(Object.values(Filter));
  const selectedFilter = validFilters.has(selectedFilterString as Filter)
    ? (selectedFilterString as Filter)
    : undefined;
  const counts = countBy(designs, 'furniture_type');
  const options = getOptions(counts);

  let filteredDesigns = designs.filter((item) => {
    if (selectedFilter && filterToFurnitureTypes[selectedFilter]) {
      return filterToFurnitureTypes[selectedFilter].includes(
        item.furniture_type as FurnitureType
      );
    }
    return Boolean(item.furniture_type);
  });

  if (!filteredDesigns.length) filteredDesigns = designs;

  return {
    filteredDesigns,
    FurnitureTypeFilter,
    selectedFilter,
    updateFilter,
    options,
  };

  function getOptions(counts: Record<string, number>): Option[] {
    const filterMapping: Record<FurnitureType, Filter> = {
      [FurnitureType.Shelf]: Filter.ShelvesAndCabinets,
      [FurnitureType.Pyllow]: Filter.Pyllow,
      [FurnitureType.Cushion]: Filter.Cushion,
      [FurnitureType.Flayr]: Filter.FlayrSofas,
    };

    const validFiltersArray = Object.values(Filter);

    const options = Object.keys(counts)
      .filter((key): key is FurnitureType => key in filterMapping)
      .map((key) => {
        const furnitureType = filterMapping[key];
        return {
          furnitureType,
          furnitureTypes: filterToFurnitureTypes[furnitureType],
          label: furnitureType,
          localizedLabel: getFurnitureLabel
            ? I18nUtils.localize(locale, `filters/subcat/${furnitureType}`)
            : '',
          quantity: counts[key],
        };
      });

    if (validFilters.has(Filter.AllSofas)) {
      options.push({
        furnitureType: Filter.AllSofas,
        furnitureTypes: filterToFurnitureTypes[Filter.AllSofas],
        label: Filter.AllSofas,
        localizedLabel: getFurnitureLabel
          ? I18nUtils.localize(locale, `filters/subcat/${Filter.AllSofas}`)
          : 'All Sofas',
        quantity:
          (counts[FurnitureType.Pyllow] || 0) +
          (counts[FurnitureType.Flayr] || 0),
      });
    }

    return options.sort(
      (a, b) =>
        validFiltersArray.indexOf(a.furnitureType) -
        validFiltersArray.indexOf(b.furnitureType)
    );
  }

  function FurnitureTypeFilter() {
    const filterOptions = getFilterOptions(options).filter(
      (option) => option.furnitureType !== Filter.Pyllow
    );

    if (filterOptions.length <= 1) return null;

    return (
      <CountFilter
        options={filterOptions}
        onSelect={updateFilter}
        selectedLabel={selectedFilter}
      />
    );

    function getFilterOptions(options: Option[]): Option[] {
      return uniqBy(options, (option) => option.localizedLabel).map(
        ({ label, localizedLabel, furnitureType, furnitureTypes }) => {
          const similarOptions = options.filter(
            (option) => option.localizedLabel === localizedLabel
          );
          return {
            label,
            localizedLabel,
            furnitureType,
            furnitureTypes,
            quantity: sum(similarOptions.map((option) => option.quantity)),
          };
        }
      );
    }
  }
}
